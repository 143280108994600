import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { STRIPE_PUBLIC_KEY } from "../../config";
import "../../css/verticalCenter.css";
import sleep from "../../utils/sleep";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../utils/fetchWrapper";
import eventManager from "../../utils/event/eventManager";

export default function ConfirmPayment() {
    const [message, setMessage] = useState("Saving your payment details...");

    const navigate = useNavigate();

    const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
    );

    if (!clientSecret) {
        window.location.href = "/";
    }

    function verifyStripe() {
        const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
        stripePromise.then(stripe => {
            // Retrieve the "setup_intent_client_secret" query parameter appended to
            // your return_url by Stripe.js
            // Retrieve the SetupIntent
            stripe
                .retrieveSetupIntent(clientSecret)
                .then(({ setupIntent }) => {
                    // Inspect the SetupIntent `status` to indicate the status of the payment
                    // to your customer.
                    //
                    // Some payment methods will [immediately succeed or fail][0] upon
                    // confirmation, while others will first enter a `processing` state.
                    //
                    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                    switch (setupIntent.status) {
                        case 'succeeded':
                            setMessage('Success! Your payment method has been saved.');
                            sleep(300).then(async () => {
                                const is_free_plan = await getRequest("/plan/is-free");
                                console.log(is_free_plan);
                                if (is_free_plan.free) {
                                    // TODO: Navigate to setting up account maybe
                                    eventManager.callEvent("planChoosen");
                                } else {
                                    navigate("/bill-account");
                                }
                            });
                            break;
                        case 'processing':
                            break;
                        case 'requires_payment_method':
                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                            setMessage('Failed to process your payment details. Please refresh your page and try with another payment method.');
                            break;
                        default:
                            setMessage(`Unknown setup intent status: ${setupIntent.status}, please contact support`);
                            break;
                    }
                });
        });
    }

    useEffect(() => {
        const stripeInterval = setInterval(verifyStripe, 2000);
        return () => clearInterval(stripeInterval);
    }, []);

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>Thank you!</b>
                    <h5 className="text-muted">We are saving your payment details and billing your account, it might take up to few seconds please wait</h5>
                </div>
                <div className="card text-center box-shadow">
                    <div className="card-body login-card-body">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <h6 className="mt-3">{message}</h6>
                    </div>
                </div>
            </div>

        </div>
    );
}
