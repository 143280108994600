export default function CurlDoc(props) {

    function copy(event) {
        event.preventDefault();
        navigator.clipboard.writeText(props.content);
    }

    return (
        <div class="card card-outline border-secondary card-info">
            <div class="card-header d-flex align-items-center">
                <h3 class="card-title">
                    {props.title}
                </h3>
                <button onClick={copy} className="ml-auto btn-sm btn-secondary mr-n2">
                    Copy
                </button>
            </div>
            <div className="card-body bg-dark">
                {props.content}
            </div>
        </div>
    );
}
