export default function PlanComponent(props) {
    const plan = props.plan;

    function choosePlan(event) {
        event.preventDefault();
        props.target(plan.id);
    }

    let disabled = plan.button.disabled ? "disabled" : "";
    let color = plan.button.color ? plan.button.color : "primary";
    let button = <button type="button" onClick={disabled ? undefined : choosePlan} className={`btn btn-lg btn-block btn-outline-${color} ${disabled}`}>{plan.button.text}</button>

    if (plan.button.full) {
        button = <button type="button" onClick={disabled ? undefined : choosePlan} className={`btn btn-lg btn-block btn-${color} ${disabled}`}>{plan.button.text}</button>
    }

    let price = <h1>
        ${plan.price} <small className="text-muted">/ mo</small>
    </h1>

    if (plan.price === "Custom") {
        price = <h1>{plan.price}</h1>
    }

    return (
        <div className="card mb-4 box-shadow col-sm-12 col-md-3">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">{plan.name}</h4>
            </div>
            <div className="card-body" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "20em"
            }}>
                {price}
                <h6 className="card-subtitle text-muted">{plan.subtitle}</h6>
                <ul className="list-unstyled mt-3 mb-4">
                    {plan.features.map(feature => <li key={feature}>{feature}</li>)}
                </ul>
                {button}
            </div>
        </div>
    );
}
