import { useState } from "react";
import download from "downloadjs";

import { OUR_URL, PLANS, STRIPE_PUBLIC_KEY } from "../../config";
import PlanDeck from "../payment/PlanDeck";
import DangerAlert from "../utils/alerts/DangerAlert";
import WarningAlert from "../utils/alerts/WarningAlert";
import HorizontalDescription from "../utils/description/HorizontalDescription";
import Table from "../utils/tables/Table";
import { deleteRequest, getFile, getRequest, postRequest } from "../../utils/fetchWrapper";
import { formatAdditional, formatCurrency, formatNumber } from "../../utils/moneyUtils";
import Confirmation from "../utils/alerts/Confirmation";
import Toast, { ToastType } from "../utils/Toast";
import CardPopup from "../stripe/CardPopup";
import { loadStripe } from "@stripe/stripe-js";
import Overlay from "../utils/overlay/Overlay";
import { useNavigate } from "react-router-dom";

export default function Billing() {
    // TODO: In case of no subscription
    const [data, setData] = useState({
        "cardBrand": null,
        "cardLast4": null,
        "planName": "No subscription",
        "planRequestPerMonth": 0,
        "planRequestPerSecond": 0,
        "planPricePerAdditional": "$0.00",
        "additionalPrice": "$0.00",
        "additionalAmount": 0,
        "paymentDue": "$0.00",
        "planId": -42,
        "planPrice": 0,
        "periodEnd": "",
        "invoices": []
    });
    const [displayAdditionalModal, setDisplayAdditionalModal] = useState(false);
    const [displayDeleteCardModal, setDisplayDeleteCardModal] = useState(false);
    const [underBilling, setUnderBilling] = useState(false);
    const [success, setSuccess] = useState("");
    const [warning, setWarning] = useState("");
    const [error, setError] = useState("");
    const [setupIntentSecret, setSetupIntentSecret] = useState("");
    // newPlan is the new plan that the user is willing to buy. It also have an
    // additional field from a normal plan (a normal plan is a plan from the PLANS const in config)
    // The additional field it have is: displayed_price. We send this price to the server to make sure
    // that if we have to bill the client, we won't bill him more than required.
    const [newPlan, setNewPlan] = useState(null);
    const [changePlanOverlay, setChangePlanOverlay] = useState(false);
    const [confirmPayBill, setConfirmPayBill] = useState(null);
    const navigate = useNavigate();

    function refresh() {
        function formatInvoices(result_invoices) {
            const ret = [];
            for (const invoice of result_invoices) {
                let description = `Subscription to the ${invoice.plan_name} plan`;
                if (invoice.type === "USAGE") {
                    description = "Additional usage for period";
                }
                ret.push({
                    id: "2023-" + invoice.id,
                    period: invoice.period_start + " - " + invoice.period_end,
                    description: description,
                    amountPaid: formatCurrency(invoice.amount_paid),
                    amountDue: formatCurrency(invoice.amount_due)
                });
            }
            return ret;
        }

        getRequest("/dashboard/billing/billing-data").then(result => {
            setData({
                cardBrand: result.card.brand,
                cardLast4: result.card.last_digits,
                planName: result.plan.name,
                planRequestPerMonth: formatNumber(result.plan.request_per_month),
                planRequestPerSecond: result.plan.request_per_second,
                planPricePerAdditional: formatCurrency(result.plan.price_per_additional),
                additionalPrice: formatAdditional(result.usage.additional_requests, result.plan.price_per_additional),
                additionalAmount: Math.ceil(result.usage.additional_requests / 1000000),
                paymentDue: formatCurrency(result.billing.amount_due),
                planId: result.plan.id,
                planPrice: result.plan.price,
                periodEnd: result.plan.period_end, // TODO: What if there is no period end?
                nextMonthPlanId: result.plan.changed_to,
                autoRenewal: result.plan.auto_renewal, // Be careful, this will be false if the next month plan id is set
                invoices: formatInvoices(result.billing.invoices)
            });
        });
    }

    useState(() => {
        refresh();
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );
        // Show messages in case of payment success
        if (clientSecret) {
            const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
            stripePromise.then(stripe => {
                stripe
                    .retrieveSetupIntent(clientSecret)
                    .then(({ setupIntent }) => {
                        // Inspect the SetupIntent `status` to indicate the status of the payment
                        // to your customer.
                        //
                        // Some payment methods will [immediately succeed or fail][0] upon
                        // confirmation, while others will first enter a `processing` state.
                        //
                        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                        switch (setupIntent.status) {
                            case 'succeeded':
                                setSuccess('Your payment method has been saved.');
                                break;
                            case 'processing':
                                setWarning("Your new payment method adding is under processing, please refresh the page in few seconds");
                                break;
                            case 'requires_payment_method':
                                setError("An error occured while trying to add a new payment method, please contact support.");
                                break;
                            default:
                                setError(`Unknown status of payment: ${setupIntent.status}, please contact support`);
                                break;
                        }
                    });
            });
        }
    }, []);

    function payAdditionalClick() {
        setDisplayAdditionalModal(true);
    }

    async function confirmPayment(client_secret, success_message) {
        const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(client_secret);
        if (errorAction) {
            setError(errorAction.message);
        } else {
            const confirm_result = await postRequest("/dashboard/billing/confirm-payment", {
                payment_intent_id: paymentIntent.id
            });
            if (confirm_result.error) {
                setError(confirm_result.error);
            } else {
                setSuccess(success_message);
            }
        }
    }

    async function payAdditional() {
        setUnderBilling(true);
        setDisplayAdditionalModal(false);
        try {
            const result = await postRequest("/dashboard/billing/pay-additional", {
                displayed_amount: data.additionalPrice
            });
            if (result.success) {
                setSuccess(`Succesfuly paid ${data.additionalPrice} for your additional usage.`);
            } else if (result.warning) {
                setWarning(result.warning);
            } else if (result.error) {
                setError(result.error);
            } else if (result.require_action) {
                await confirmPayment(result.client_secret, `Succesfuly paid ${data.additionalPrice} for your additional usage.`);
            } else {
                throw new Error("payAdditional: Impossible for result: " + JSON.stringify(result));
            }
            setUnderBilling(false);
            refresh();
        } catch {
            setUnderBilling(false);
            setError("Too many requests, please try again in few seconds");
        }
    }

    /**
    * Change the next plan to the next plan of the user (the one that is in newPlan)
    */
    async function changePlan() {
        const plan = newPlan;
        setNewPlan(null);
        let is_upgrade = plan.price > data.planPrice / 100;
        if (plan.id === data.planId && data.nextMonthPlanId === null) {
            const result = await postRequest("/dashboard/api-usage/enable-auto-renewal");
            if (result.error) {
                setError(result.error);
            } else {
                setSuccess("Succesfully enabled auto renewal");
                refresh();
            }
        } else {
            setChangePlanOverlay(true);
            const result = await postRequest("/dashboard/billing/change-plan", {
                price: plan.displayed_price * 100, // We send the price so that if we bill the user, we know that it will be the same amount
                is_upgrade: is_upgrade, // If the user plan is an upgrade or not (the server will check it itself and it has to be the same)
                id: plan.id
            });
            if (result.success) {
                setChangePlanOverlay(false);
                if (is_upgrade) {
                    setSuccess(`Succesfuly upgraded your plan to ${newPlan.name} and billed your account of ${formatCurrency(plan.displayed_price * 100)}`);
                } else {
                    setSuccess("Next month your plan will be set to " + newPlan.name);
                }
            } else {
                if (result.error) {
                    setChangePlanOverlay(false);
                    setError(result.error);
                } else if (result.require_action) {
                    await confirmPayment(result.client_secret, `Succesfuly upgraded your plan to ${newPlan.name} and billed your account of ${formatCurrency(plan.displayed_price * 100)}`)
                    setChangePlanOverlay(false);
                } else {
                    setChangePlanOverlay(false);
                    console.log(result);
                    setError("Unknown error occured while setting up your payment please check console and contact support");
                }
            }
            refresh();
        }
    }

    /**
     * Allow us to pay for a specific invoice
     * @param {string} invoice_id The id of the invoice that the use which to pay
     */
    function payDueClick(invoice_id, displayed_amount) {
        setConfirmPayBill({
            invoice_id: invoice_id,
            displayed_amount: displayed_amount
        });
    }

    async function payDueBill() {
        const invoice_id = confirmPayBill.invoice_id;
        const displayed_amount = confirmPayBill.displayed_amount;
        setConfirmPayBill(null);
        setUnderBilling(true);
        try {
            const pay_bill_request = await postRequest("/dashboard/billing/pay-due-bill", {
                invoice_id: invoice_id,
                displayed_amount: displayed_amount
            });
            if (pay_bill_request.success) {
                setSuccess(`Succesfully paid your due bill for invoice: ${invoice_id}`);
            } else if (pay_bill_request.require_action) {
                await confirmPayment(pay_bill_request.client_secret, `Succesfully paid your due bill for invoice: ${invoice_id}`);
            } else {
                setError(pay_bill_request.error);
            }
            setUnderBilling(false);
            refresh();
        } catch {
            setUnderBilling(false);
            setError("Too many requests, please wait few seconds");
        }

    }

    async function downloadInvoice(id) {
        const res = await getFile("/dashboard/billing/download-invoice?invoice_id=" + id);
        const blob = await res.blob();
        download(blob, id + ".pdf");
    }

    function deleteCardClick() {
        if (data.paymentDue !== "$0.00") {
            setError(`You have a payment due of ${data.paymentDue}. You can't remove your card until the bill is paid.`);
        } else if (data.additionalPrice !== "$0.00") {
            setError("You have some additional requests to pay. You can't remvove your card until they are paid");
        } else {
            setDisplayDeleteCardModal(true);
        }
    }

    function deleteUserCard() {
        setDisplayDeleteCardModal(false);
        deleteRequest("/dashboard/billing/delete-card").then(result => {
            if (result.error) {
                setError(result.error);
            } else {
                refresh();
                setSuccess("Succesfuly deleted your card, your card can no longer be charged by us.");
            }
        });
    }

    async function editCardClick() {
        try {
            const setupIntent = await postRequest("/dashboard/billing/create-setup-intent");
            if (setupIntent.error) {
                setError(setupIntent.error);
            } else {
                setSetupIntentSecret(setupIntent.clientSecret);
            }
        } catch {
            setError("Too many requests, please try again in few seconds");
        }
    }

    /**
    * Allow us to change the plan according to the options
    * @returns The plans that we display
    */
    function fillPlan() {
        // Structured clone is the native way of cloning:
        // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
        const ret = structuredClone(PLANS);
        for (const plan of ret) {
            if (data.nextMonthPlanId !== null) {
                if (plan.id === data.nextMonthPlanId) {
                    plan.button.disabled = true;
                    plan.button.text = "Next month";
                } else if (plan.id === data.planId) {
                    plan.button.text = "Keep current";
                } else if (plan.price > data.planPrice / 100) {
                    plan.button.text = "Upgrade $" + (plan.price - data.planPrice / 100);
                } else if (plan.id >= 0) {
                    plan.button.text = "Downgrade";
                }
            } else {
                if (plan.id === data.planId) {
                    if (data.autoRenewal) {
                        plan.button.disabled = true;
                        plan.button.text = "Current";
                    } else {
                        plan.button.text = "Enable auto renewal";
                    }
                } else if (plan.price > data.planPrice / 100) {
                    plan.button.text = "Upgrade $" + (plan.price - data.planPrice / 100);
                } else if (plan.id >= 0) {
                    plan.button.text = "Downgrade";
                }
            }

        }
        return ret;
    }

    const plans = fillPlan();

    function choosePlan(id) {
        if (id === -1) {
            navigate("/support");
            return;
        }
        let new_plan = null
        for (const plan of PLANS) {
            if (plan.id === id) {
                new_plan = plan;
                break;
            }
        }
        for (const plan of plans) {
            if (plan.id === id && plan.price > data.planPrice / 100) {
                new_plan.displayed_price = plan.price - data.planPrice / 100;
                break;
            }
        }
        setNewPlan(new_plan);
    }

    let additional = null;
    let due_bill = null;

    if (data.paymentDue !== "$0.00") {
        due_bill = data.invoices.filter(invoice => invoice.amountDue !== "$0.00").map(invoice => {
            return (<DangerAlert title="Payment due"
                content={<span>You have a due bill of {invoice.amountDue}, you need to pay it in order to continue using our service. If you have any question do not hesitate to <a href="mailto:contact@blockchainapis.io">contact us</a>.</span>}
                button="Pay now"
                buttonAction={() => payDueClick(invoice.id, invoice.amountDue)}
                overlayText={underBilling ? "Billing your account please wait..." : undefined} />)
        });
    } else if (data.additionalPrice !== "$0.00") {
        additional = (<WarningAlert title="Future payment"
            content={`You have an additional usage of ${data.additionalAmount} million requests for this month which cost ${data.additionalPrice}, your account will be automatically billed on ${data.periodEnd}. You can alternatively pay now.`}
            button="Pay now"
            buttonAction={payAdditionalClick}
            overlayText={underBilling ? "Billing your account please wait..." : undefined} />);
    }

    let invoices = <Table title={<span><i className="fa-solid fa-file-invoice"></i> Invoices</span>}
        header={["Bill id", "Period", "Description", "Amount paid", "Amount due", "Invoice"]}
        body={Object.keys(data.invoices).map(invoice => [
            data.invoices[invoice].id,
            data.invoices[invoice].period,
            data.invoices[invoice].description,
            data.invoices[invoice].amountPaid,
            data.invoices[invoice].amountDue,
            <button className="btn btn-link p-0 d-flex align-items-center" onClick={() => downloadInvoice(data.invoices[invoice].id)}> Invoice {data.invoices[invoice].id} <small className="ml-1"><i className="fa-solid fa-download"></i></small></button>
        ])} />

    if (data.invoices.length === 0) {
        invoices = <div className="card">
            <div className="card-header">
                <h3 className="card-title">
                    <span><i className="fa-solid fa-file-invoice"></i> Invoices</span>
                </h3>
            </div>
            <div className="card-body">
                You don't have any invoices yet
            </div>
        </div>
    }

    let modal = null;
    if (displayAdditionalModal) {
        modal = <Confirmation title="Pay for additional usage?"
            close={() => setDisplayAdditionalModal(false)}
            saveButton="Yes"
            closeButton="No"
            body={`Are you sure that you want to pay for your additional usage? Your account will be billed of ${data.additionalPrice}.`}
            confirm={payAdditional}
            color="success" />
    } else if (displayDeleteCardModal) {
        modal = <Confirmation title="Delete card?"
            close={() => setDisplayDeleteCardModal(false)}
            saveButton="I am sure"
            closeButton="No thanks"
            body={`Are you sure that you want to delete your card? Your automatic renewal will be disabled and you will not be able to make requests above the one included in your ${data.planName} plan.`}
            confirm={deleteUserCard}
            color="danger" />
    } else if (newPlan !== null) {
        if (newPlan.price > data.planPrice / 100) {
            modal = <Confirmation title={`Upgrade subscription to ${newPlan.name}?`}
                close={() => setNewPlan(null)}
                saveButton="Yes"
                closeButton="no"
                body={`Are you sure that you want to upgrade your subscription to ${newPlan.name}? Your account will be billed of ${formatCurrency(newPlan.displayed_price * 100)}. Your new ${newPlan.name} subscription will be active until the end of the current period: ${data.periodEnd}. Auto renewal will be automatically enabled for the new subscription and if you do not deactivate it, you will be billed of ${formatCurrency(newPlan.price * 100)} on ${data.periodEnd}.`}
                confirm={changePlan}
                color="success" />
        } else if (newPlan.id === data.planId) {
            let title = "Keep your current subscription?"
            let text = `Are you sure that you want to keep your current ${newPlan.name} subscription?`;
            let color = "danger";
            if (newPlan.price > 0) {
                text = `Are you sure that you want to keep your current ${newPlan.name} subscription? Your account will be billed of ${formatCurrency(data.planPrice)} on ${data.periodEnd}`;
                color = "success";
            }
            if (data.nextMonthPlanId === null) {
                title = "Enable auto renewal?";
                if (data.planPrice > 0) {
                    text = `Are you sure that you want to enable auto renewal? Your account will be billed of ${formatCurrency(data.planPrice)} on ${data.periodEnd}`;
                } else {
                    text = "Are you sure that you want to enable auto renewal?";
                }
                color = "success";
            }
            modal = <Confirmation title={title}
                close={() => setNewPlan(null)}
                saveButton="Yes"
                closeButton="no"
                body={text}
                confirm={changePlan}
                color={color} />
        } else {
            let text = `Are you sure that you want to downgrade your subscription? On ${data.periodEnd} you will be billed of ${formatCurrency(newPlan.price * 100)} with less requests.`;
            if (newPlan.price === 0) {
                text = `Are you sure that you want to downgrade your subscription? If you do that, from ${data.periodEnd}, your subscription will be more limited.`;
            }
            modal = <Confirmation title={`Downgrade to ${newPlan.name} subscription?`}
                close={() => setNewPlan(null)}
                saveButton="I am sure"
                closeButton="No thanks"
                body={text}
                confirm={changePlan}
                color="danger" />
        }
    } else if (confirmPayBill) {
        modal = <Confirmation title={"Pay due bill?"}
            close={() => setConfirmPayBill(null)}
            saveButton="yes"
            closeButton="no"
            body={`Are you sure that you want to pay your due bill? Your account will be billed of ${confirmPayBill.displayed_amount}`}
            confirm={payDueBill}
            color="success" />
    }

    let toast = null;
    if (error) {
        toast = <Toast toastType={ToastType.danger}
            title="error"
            closeFunction={() => setError("")}
            content={error} />
    } else if (warning) {
        toast = <Toast toastType={ToastType.warning}
            title="Warning"
            closeFunction={() => setWarning("")}
            content={warning} />
    } else if (success) {
        toast = <Toast toastType={ToastType.success}
            title="Success"
            closeFunction={() => setSuccess("")}
            content={success} />
    }

    let cardDetails = <HorizontalDescription icon="fas fa-credit-card"
        title="Card details"
        lines={[
            ["Brand", data.cardBrand ? data.cardBrand : "No card"],
            ["Number", data.cardLast4 ? "**** **** **** " + data.cardLast4 : "No card"]
        ]}>
        <div className="row">
            <div className="col-md-2">
                <button className="btn btn-primary btn-block" onClick={editCardClick}><span><i className="fa-solid fa-pen-to-square"></i> Edit</span></button>
            </div>
            {data.cardBrand && <div className="col-md-2">
                <button className="btn btn-danger btn-block" onClick={deleteCardClick}><span><i className="fa-solid fa-trash"></i> Delete</span></button>
            </div>}
        </div>
    </HorizontalDescription>
    if (setupIntentSecret) {
        cardDetails = <CardPopup secret={setupIntentSecret}
            title={<span><i className="fas fa-credit-card"></i> Card details</span>}
            buttonText="Modify card"
            setError={setError}
            cancel={() => setSetupIntentSecret("")}
            return_url={OUR_URL + "/billing"} />
    }

    let change_plan_overlay = null;
    if (changePlanOverlay) {
        change_plan_overlay = <Overlay overlayText="Changing your subscription, please wait..." />
    }

    return (
        <div>
            {toast}
            {modal}
            {additional}
            {due_bill}
            <div className="row">
                <div className="col-md-6">
                    {cardDetails}
                </div>
                <div className="col-md-6">
                    <HorizontalDescription icon="fas fa-user"
                        title={"Current plan: " + data.planName}
                        lines={[
                            ["Requests per month", data.planRequestPerMonth],
                            ["Requests per second", data.planRequestPerSecond],
                            ["Price per additional million request", data.planPricePerAdditional]
                        ]}>
                        <div className="mt-n4"></div>
                    </HorizontalDescription>
                </div>
                <div className="col-md-12">
                    {invoices}
                </div>
                <div className="col-md-10 offset-md-1">
                    <div className="card">
                        <div className="card-header bg-primary">
                            <h3 className="card-title">
                                Change plan
                            </h3>
                        </div>
                        <div className="card-body d-flex justify-content-center mb-n2">
                            {change_plan_overlay}
                            <PlanDeck choosePlan={choosePlan} plans={plans} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
