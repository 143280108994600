import { useState } from "react";
import authHandler from "../../utils/AuthHandler";
import Toast from "../utils/Toast";

import { useNavigate } from "react-router-dom";

export default function Register() {
    const [error, setError] = useState("");

    const navigate = useNavigate();

    async function emailRegister(event) {
        event.preventDefault();
        const email = event.target.email.value;
        if (!email) {
            setError("Please specify a valid email");
            return;
        }
        try {
            // TODO: This is not very beautiful (but it works)
            await authHandler.register_email(email);
            navigate("/thank-you");
        } catch {
            setError("Too many requests, please try again later.");
        }
    }

    let toast = null;
    if (error) {
        toast = <Toast title="Error" content={error} closeFunction={() => setError("")} />;
    }

    return (
        <div className="login-page">
            {toast}
            <div className="login-box">
                <div className="login-logo">
                    <p><b>Blockchain APIs</b></p>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign up with email</p>
                        <form onSubmit={emailRegister}>
                            <div className="input-group mb-3">
                                <input name="email" type="email" className="form-control" placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Sign up</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="text-center card-footer">
                        <a href="/login">Already have an account? Login</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
