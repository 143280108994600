import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { STRIPE_PUBLIC_KEY } from "../../config";

function CardFormPopup(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        setButtonDisabled(true);

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: props.return_url,
            },
        });
        setButtonDisabled(false);

        if (error) {
            console.log(props);
            props.setError(error.message);
        }
    }

    return (
        <div className='card'>
            <div className='card-header'>
                <h5 className='card-title'>{props.title}</h5>
            </div>
            <form onSubmit={handleSubmit} className="card-body">
                <PaymentElement options={{
                    business: {
                        name: "Blockchain APIs"
                    }
                }} />
                <div className="pt-3 row">
                    <div className="col-md-2">
                        <button type="button" className={"btn btn-danger btn-block " + (isButtonDisabled ? "disabled" : "")} onClick={props.cancel}>Cancel</button>
                    </div>
                    <div className="col-md-5 offset-md-5">
                        <button type="submit" className={"btn btn-primary btn-block " + (isButtonDisabled ? "disabled" : "")}>
                            <i className='fas fa-regular fa-lock' /> {props.buttonText}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function CardPopup(props) {
    const options = {
        clientSecret: props.secret,
        appearance: {
            theme: "stripe"
        }
    };

    return <Elements stripe={stripePromise} options={options}>
        <CardFormPopup title={props.title} buttonText={props.buttonText} return_url={props.return_url} cancel={props.cancel} setError={props.setError} />
    </Elements>
}
