import { BANNED_COUNTRIES, EUROPEAN_UNION_COUNTRIES } from "./countryConfig";

const european_codes = new Set(EUROPEAN_UNION_COUNTRIES);
const banned_codes = new Set(BANNED_COUNTRIES);

export function isEuropean(country_code) {
    return european_codes.has(country_code);
}

export function isBanned(country_code) {
    return banned_codes.has(country_code);
}
