import eventManager from "./event/eventManager";
import { getRequest, postRequest } from "./fetchWrapper";

class AuthHandler {

    constructor() {
        this.interval = null;
    }

    startAuthRefresher() {
        const method = this.is_connected;
        const stop_method = this.stopAuthRefresher;
        // refresh the auth token every 2 minutes
        this.interval = setInterval(() => {
            // refresh the token
            if (!method()) {
                stop_method();
                eventManager.callEvent("userDisconnect")
            }
        }, 1000 * 60 * 2);
    }

    stopAuthRefresher() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    async forgotPassword(email) {
        return await postRequest("/auth/forgot-password", {
            email: email
        });
    }

    async resetPassword(password, token) {
        return await postRequest("/auth/reset-password", {
            password: password,
            jwt_token: token
        });
    }

    /**
     * Verify if the user is connected by sending a get request to the server.
     * It will return true if the user is connected, false otherwise
    */
    async is_connected() {
        const ret = await getRequest("/auth/refresh-token");
        return !!ret.success;
    }

    /**
     * This method will try to reconnect the user using the long-term authentication cookie.
     */
    async try_reconnect() {
        const ret = await getRequest("/auth/re-auth");
        return !!ret.success;
    }

    async connect(email, password, remember) {
        return await postRequest("/auth/login", {
            email: email,
            password: password,
            remember: remember
        });
    }

    async register_email(email) {
        await postRequest("/auth/register-email", {
            "email": email
        });
    }

    async register(first_name, last_name, email, country, password, jwt_token, remember, is_company, company, address, city, zip_code, vat) {
        return await postRequest("/auth/register", {
            first_name: first_name,
            last_name: last_name,
            email: email,
            country: country,
            password: password,
            jwt_token: jwt_token,
            remember: remember,
            is_company: is_company,
            company: company,
            address: address,
            city: city,
            zip_code: zip_code,
            vat: vat
        });
    }
}

const authHandler = new AuthHandler();

// This is a singleton
export default authHandler;
