export default function BoxInfo(props) {
    let size = "col-md-3 col-sm-6 col-12";
    if (props.size) {
        size = props.size;
    }
    return (
        <div className={size}>
            <div className="info-box">
                <span className={"info-box-icon " + props.color}><i className={props.icon}></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">{props.textUp}</span>
                    <span className="info-box-number">{props.textDown}</span>
                </div>
            </div>
        </div>
    )
}
