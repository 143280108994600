import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import Toast from "../utils/Toast";
import { OUR_URL } from "../../config";

export default function CardForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [underProcessing, setUnderProcessing] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setUnderProcessing(true);
        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: OUR_URL + '/confirm-payment',
            },
        });
        if (error) {
            setErrorMessage(error.message);
            setUnderProcessing(false);
        }
        // otherwise the payment succeeded, we don't need to do anything
    }
    let toast = null;
    if (errorMessage) {
        toast = <Toast title="Error"
                       closeFunction={() => setErrorMessage(null)}
                       content={errorMessage} />
    }

    let title = `Subscribe $${props.amount}/month`;
    let button_text = "Subscribe";
    if (props.amount <= 0) {
        title = "Please provide your card details"
        button_text = "Save";
    }
    return (
        <div className="login-page">
            {toast}
            <div className="login-box">
                <div className='card'>
                    <div className='card-header'>
                        <h5 className='card-title'>{title}</h5>
                    </div>
                    <form onSubmit={handleSubmit} className="card-body">
                        <PaymentElement options={{
                            business: {
                                name: "Blockchain APIs"
                            }
                        }} />
                        <div className="pt-3">
                            <button type="submit" className={"btn btn-primary btn-block " + (underProcessing ? "disabled" : "")}><i className='fas fa-regular fa-lock' /> {button_text}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>);

    /*return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button disabled={!stripe}>Submit</button>
                {errorMessage && <div>{errorMessage}</div>}
            </form>
        </div>
    );*/
}
