import { useNavigate } from "react-router-dom";
import authHandler from "../../utils/AuthHandler";

export default function ForgotPassword() {

    const navigate = useNavigate();

    function forgotPassword(e) {
        e.preventDefault();
        const email = e.target.email.value;
        authHandler.forgotPassword(email);
        navigate("/forgot-password-message");
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <p><b>Blockchain APIs</b></p>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">You forgot your password? Here you can easily retrieve your password.</p>
                        <form onSubmit={forgotPassword}>
                            <div className="input-group mb-3">
                                <input name="email" type="email" className="form-control" placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Request new password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
