import { PLANS } from "../../config";
import "../../css/verticalCenter.css";
import PlanDeck from "./PlanDeck";

export default function PlanChoosing(props) {

    return (
        <div className="vertical-center">
            <div className="container">
                <div class="text-center pb-3">
                    <h1 class="display-4">Choose your plan</h1>
                    <p class="lead">Choose the plan that best fits your needs</p>
                </div>
                <PlanDeck choosePlan={props.choosePlan} plans={PLANS} />
            </div>
        </div>)
}
