import Overlay from "../overlay/Overlay";

export default function AllAlert(props) {
    let icon = "fa-ban";
    if (props.type === "warning") {
        icon = "fa-exclamation-triangle";
    }
    let overlay = null;

    if (props.overlayText) {
        overlay = <Overlay overlayText={props.overlayText} />
    }

    if (props.button) {
        return (
            <div className={`alert alert-${props.type} alert-dismissible`}>
                {overlay}
                <div className="d-flex justify-content-between align-items-end">
                    <div>
                        <h5><i className={`icon fas ${icon}`} /> {props.title}</h5>
                        {props.content}
                    </div>
                    <button onClick={props.buttonAction} className="btn btn-success mr-n5">{props.button}</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`alert alert-${props.type} alert-dismissible`}>
                {overlay}
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className={`icon fas ${icon}`} /> {props.title}</h5>
                {props.content}
            </div>
        )
    }

}
