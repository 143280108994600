import { useEffect, useState } from "react";
import { API_URL, DISCORD_INVITE, DOC_URL } from "../../config";
import { getRequest, postRequest } from "../../utils/fetchWrapper";
import Confirmation from "../utils/alerts/Confirmation";
import DangerAlert from "../utils/alerts/DangerAlert";
import CurlDoc from "../utils/docs/CurlDoc";
import Copy from "../utils/form/Copy";
import MultiTab from "../utils/multiTab/MultiTab";
import Toast, { ToastType } from "../utils/Toast";

function Curl(props) {
    return (
        <div>
            <Copy inputId="api-key" label="API key" value={props.apiKey} additionalButton={<button onClick={props.reset} className="input-group-text bg-danger">Reset</button>} />
            <label>How to use</label><br />
            You need to put your API key in the HTTP header: <span className="text-muted">api-key</span>
            <div className="mt-3">
                <label>Examples</label>
                <CurlDoc title="Get available blockchains" content={`curl -H "api-key: ${props.apiKey}" -X 'GET' '${API_URL}/v0/blockchains/' -H 'accept: application/json'`}/>
                <CurlDoc title="Get ETH/USDT reserves in Ethereum" content={`curl -H "api-key: ${props.apiKey}" -X 'GET' '${API_URL}/v0/exchanges/pairs/reserves?blockchain=ethereum&token0=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2&token1=0xdAC17F958D2ee523a2206206994597C13D831ec7' -H 'accept: application/json'`} />
                If you need any help, feel free to join <a href={DISCORD_INVITE}>our discord</a> <br />
                See more examples in <a href={`${DOC_URL}`} target="_blank" rel="noreferrer">Documentation</a>
            </div>
        </div>
    );
}

export default function ApiKey() {
    // TODO: Change this to null
    const [apiKey, setApiKey] = useState(null);
    const [isEnabled, setEnabled] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isReset, setReset] = useState(false);

    function resetClick(e) {
        e.preventDefault();
        setReset(true);
    }

    function changeApiKey(e) {
        e.preventDefault();
        setReset(false);
        postRequest("/dashboard/change-api-key").then(result => {
            if (result.error) {
                setError(result.error);
            } else {
                setApiKey(result.new_api_key);
                setSuccess("Succesfully changed API key");
            }
        });
    }

    useEffect(() => {
        // result is like that:
        // {
        //     api_key: 'The api key',
        //     is_active: true, // The if the api key is active or not TODO: add something to say that the api key is
        //                      // inactive and that we need to take some action
        // }
        getRequest("/dashboard/api-key").then(result => {
            setApiKey(result.api_key);
            setEnabled(result.is_active);
        });
    }, []);

    const lines = [
        {
            id: "curl",
            displayName: <span><i className="fas fa-code"></i> Curl</span>,
            content: <Curl apiKey={apiKey} reset={resetClick} />
        },
        {
            id: "python",
            displayName: <span><i class="fa-brands fa-python"></i> Python</span>,
            content: <div>Coming soon</div>
        },
        {
            id: "javascript",
            displayName: <span><i class="fa-brands fa-js"></i> Nodejs</span>,
            content: <div>Coming soon</div>
        }
    ];

    let disabled = null;
    if (!isEnabled) {
        disabled = (<DangerAlert title="Disabled API key"
        content={<span>Your API key is disabled, please take a look at <a href="/billing">billing</a> for more informations.</span>} />);
    }

    let resetModal = null;
    if (isReset) {
        resetModal = (<Confirmation title="Change API key?"
                                    body="Are you sure that you want to change your API key? You will have to change the API key in all of your projects."
                                    saveButton="I am sure"
                                    closeButton="No thank you"
                                    color="danger"
                                    close={() => setReset(false)}
                                    confirm={changeApiKey} />);
    }

    let toast = null;
    if (error) {
        toast = (<Toast title="error" closeFunction={() => setError("")} content={error} />);
    }
    let success_toast = null;
    if (!error && success) {
        success_toast = (<Toast toastType={ToastType.success} title="success" closeFunction={() => setSuccess("")} content={success} />)
    }

    return (
        <div>
            {toast}
            {success_toast}
            {resetModal}
            {disabled}
            <MultiTab title={(<span><i class="fa-solid fa-key"></i> API key</span>)} lines={lines} />
        </div>
    );
}
