export default function Overlay(props) {
    return (
        <div class="overlay-wrapper">
            <div class="overlay">
                <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                <div class="text-bold text-dark pt-2 pl-3">
                    
                    {props.overlayText}
                </div>
            </div>
        </div>
    )
}