function handleClick(event, toCall, reset) {
    event.preventDefault();
    const toCallArgs = {};
    // TODO: handle input type checkbox
    for (let i = 0; i < event.target.length - 1; i++) {
        if (event.target[i].type === "checkbox") {
            toCallArgs[event.target[i].name] = event.target[i].checked;
        } else {
            toCallArgs[event.target[i].name] = event.target[i].value;
        }
    }
    if (reset) {
        event.target.reset();
    }
    toCall(toCallArgs);
}

export default function Form(props) {
    let color = props.color ? props.color : "primary";
    let button = null;
    if (props.customButton) {
        button = props.customButton;
    } else {
        button = <button className={`btn btn-${color} btn-block`} type="submit" >{props.button ? props.button : "submit"}</button>;
        if (props.buttonSize) {
            button = (
                <div className={props.buttonSize}>
                    {button}
                </div>
            );
        }
        if (!props.button) {
            button = null;
        }
    }

    const formBody = (
        <div className={props.useRow ? "row" : ""}>
            {props.children}
            {button}
        </div>
    );

    return (
        <div className={`card card-${color}`}>
            <div className="card-header">
                <h3 className="card-title">{props.title}</h3>
            </div>
            <form onSubmit={(event) => handleClick(event, props.onSubmit, props.reset)}>
                <div className="card-body">
                    {formBody}
                </div>
            </form>
        </div>
    );
}
