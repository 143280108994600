export default function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright © 2023 <a href="https://www.blockchainapis.io/">Blockchain APIs</a>. </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 0.0.1
        </div>
      </footer>
      <aside className="control-sidebar control-sidebar-dark">
      </aside>
    </div>
  );
}
