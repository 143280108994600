import { useState } from 'react';
import PlanChoosing from '../payment/PlanChoosing';
import { postRequest } from '../../utils/fetchWrapper';
import CardDetailPage from './CardDetailPage';
import { useNavigate } from 'react-router-dom';
import eventManager from '../../utils/event/eventManager';

export default function ChoosePlanPage() {
    const [clientSecret, setClientSecret] = useState("");
    const [amount, setAmount] = useState("");

    const navigate = useNavigate();

    async function choosePlan(plan) {
        // e.preventDefault();
        // const res = await stripeHandler.createSubscription(100);
        if (plan < 0) {
            navigate("/contact-page");
        } else {
            const res = await postRequest("/plan/create-subscription", {plan: plan});
            if ("error" in res) {
                // TODO: If error status is 409, we need to redirect
                console.log(res.error);
            } else {
                if (res.success) {
                    if ("clientSecret" in res) {
                        setAmount(res.amount / 100);
                        setClientSecret(res.clientSecret);
                    } else {
                        // The plan has been choosen to be free
                        eventManager.callEvent("planChoosen");
                    }
                } else {
                    console.log("An unknown error hapenned please check with an admin");
                }
            }
        }
    }

    if (clientSecret === "") {
        // TODO: Choose plan here
        // return <button onClick={click}>Click me</button>
        return <PlanChoosing choosePlan={choosePlan} />
    } else {
        return (
            <CardDetailPage secret={clientSecret} amount={amount} />
        )
    }
};
