import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../utils/fetchWrapper";
import { formatAdditional, formatCurrency, formatNumber, getAdditionalPrice } from "../../utils/moneyUtils";
import BoxColors from "../../utils/ui/BoxColors";
import Confirmation from "../utils/alerts/Confirmation";
import HorizontalDescription from "../utils/description/HorizontalDescription";
import BoxInfo from "../utils/infos/BoxInfo";
import Toast, { ToastType } from "../utils/Toast";

export default function ApiUsage() {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [usage, setUsage] = useState(null);
    const [enableModal, setEnableModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);

    const navigate = useNavigate();

    function computeBilledNextMonth(to_bill, price_per_additional, auto_renewal, subscription_price, next_month_plan_price) {
        let ret = getAdditionalPrice(to_bill, price_per_additional);
        if (next_month_plan_price) {
            ret += next_month_plan_price;
        } else if (auto_renewal) {
            ret += subscription_price;
        }
        return formatCurrency(ret);
    }

    function refresh() {
        getRequest("/dashboard/api-usage/usage").then(usage_set => {
            setUsage({
                totalRequests: formatNumber(usage_set.total_requests),
                includedRequests: formatNumber(usage_set.included_requests),
                additionalRequests: formatNumber(usage_set.additional_requests), // additional_requests is to_bill + billed
                billedForAdditional: formatAdditional(usage_set.billed_requests, usage_set.price_per_additional),
                planName: usage_set.plan_name,
                periodStart: usage_set.period_start,
                periodEnd: usage_set.period_end,
                autoRenewal: usage_set.auto_renewal,
                planPrice: formatCurrency(usage_set.plan_price),
                pricePerAdditional: formatCurrency(usage_set.price_per_additional),
                additionalUsageToPay: formatAdditional(usage_set.to_bill_request, usage_set.price_per_additional),
                billedNextMonth: computeBilledNextMonth(usage_set.to_bill_request, usage_set.price_per_additional, usage_set.auto_renewal, usage_set.plan_price, usage_set.next_month_plan_price),
                nextMonthPlanName: usage_set.next_month_plan_name,
                nextMonthPlanPrice: usage_set.next_month_plan_price !== null ? formatCurrency(usage_set.next_month_plan_price) : null,
            });
        });
    }

    useEffect(() => {
        refresh();
        // Comment required to remove warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function disableAutoRenewal() {
        setDisableModal(false);
        postRequest("/dashboard/api-usage/disable-auto-renewal").then(result => {
            if (result.error) {
                setError(result.error);
            } else {
                setSuccess("Succesfully disabled auto renewal");
                refresh();
            }
        });
    }

    function enableAutoRenewal() {
        setEnableModal(false);
        postRequest("/dashboard/api-usage/enable-auto-renewal").then(result => {
            if (result.error) {
                setError(result.error);
            } else {
                setSuccess("Succesfully enabled auto renewal");
                refresh();
            }
        });
    }

    function openDisableConfirmation() {
        setDisableModal(true);
    }

    function openEnableConfirmation() {
        setEnableModal(true);
    }

    if (!usage) {
        return <div>Loading dashboard...</div>
    }

    let autoRenewal = <span>Enabled <button type="button" class="btn badge badge-danger" onClick={openDisableConfirmation}>Disable</button></span>;
    let billedNextMonth = `${usage.billedNextMonth} (additional usage + auto renewal)`;
    if (usage.nextMonthPlanName) {
        if (usage.nextMonthPlanPrice !== "$0.00") {
            billedNextMonth = `${usage.billedNextMonth} (additional usage + new plan subscription)`;
        } else {
            billedNextMonth = `${usage.billedNextMonth} (additional usage)`;
        }
    } else if (!usage.autoRenewal) {
        autoRenewal = <span>Disabled <button type="button" class="btn badge badge-success" onClick={openEnableConfirmation}>Enable</button></span>;
        billedNextMonth = `${usage.billedNextMonth} (additional usage)`;
    }
    let additional_usage = usage.additionalUsageToPay;
    if (usage.additionalUsageToPay !== "$0.00") {
        additional_usage = <span>{usage.additionalUsageToPay} <button type="button" class="btn badge badge-success" onClick={() => navigate("/billing")}>Pay now</button></span>
    }

    let change_plan = <a type="button" href="/billing" class="btn badge badge-primary">Change</a>
    if (usage.nextMonthPlanName) {
        change_plan = null;
    }

    const lines = [
        ["Current plan", <span>{usage.planName} {change_plan}</span>],
        ["Period start", usage.periodStart],
        ["Period end", usage.periodEnd],
    ]

    if (usage.nextMonthPlanName) {
        lines.push(["Next month plan", usage.nextMonthPlanName],
            ["Next month plan price", usage.nextMonthPlanPrice],
        );
    } else {
        lines.push(["Auto renewal", autoRenewal],
            ["Plan price", usage.planPrice],
        );
    }

    lines.push(["Price per additional million request", usage.pricePerAdditional],
        ["Additional usage to pay", additional_usage],
        ["Billed on " + usage.periodEnd, billedNextMonth]
    );

    let toast = null;
    if (error) {
        toast = <Toast title="Error" content={error} closeFunction={() => setError("")} />
    } else if (success) {
        toast = <Toast toastType={ToastType.success} title="Update succesfull" content={success} closeFunction={() => setSuccess("")} />
    }

    const confirmEnableModal = <Confirmation title="Enable auto renewal?"
        body={`Are you sure that you want to enable auto renewal? You will get billed of ${usage.planPrice} on ${usage.periodEnd}.`}
        saveButton="I am sure"
        closeButton="No thank you"
        color="danger"
        close={() => setEnableModal(false)}
        confirm={enableAutoRenewal} />

    const confirmDisableModal = <Confirmation title="Disable auto renewal?"
        body={`Are you sure that you want to disable auto renewal? Your subscription will be no longer active next month.`}
        saveButton="I am sure"
        closeButton="No thank you"
        color="danger"
        close={() => setDisableModal(false)}
        confirm={disableAutoRenewal} />

    return (
        <div>
            {enableModal && confirmEnableModal}
            {disableModal && confirmDisableModal}
            {toast}
            <div className="row">
                <BoxInfo color={BoxColors.black} icon="fa-solid fa-user" textUp="Total requests made" textDown={usage.totalRequests} />
                <BoxInfo color={BoxColors.green} icon="fa-solid fa-check" textUp="Included requests in plan" textDown={usage.includedRequests} />
                <BoxInfo color={BoxColors.red} icon="fa-solid fa-plus" textUp="Additional requests" textDown={usage.additionalRequests} />
                <BoxInfo color={BoxColors.gray} icon="fa-solid fa-dollar-sign" textUp="Already paid for requests" textDown={usage.billedForAdditional} />
            </div>
            <div className="row">
                <div className="col-md-8 col-12">
                    <HorizontalDescription icon="fa-solid fa-user" title="Plan infos" lines={lines} />
                </div>
            </div>
        </div>
    );
}
