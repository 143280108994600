export default function ResetSuccesful() {
    return <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
                <a href="/"><b>Blockchain APIs</b></a>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Your password has succesfully been changed, you can now <a href="/login">login</a></p>
                </div>
            </div>
        </div>
    </div>
}
