export default function ThankYou() {
    return <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
                <a href="/"><b>Blockchain APIs</b></a>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Thank you for signing in, please check your mailbox to get the registration link</p>
                </div>
            </div>
        </div>
    </div>
}