export default function Confirmation(props) {
    let color = "primary";

    if (props.color) {
        color = props.color;
    }

    return (
        <div className="modal fade show" style={{ display: 'block' }} aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className={`modal-content bg-${color}`}>
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{props.body}</p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        {!props.hideNoButton &&
                            <button type="button" className="btn btn-outline-light" data-dismiss="modal" onClick={props.close}>{props.closeButton ? props.closeButton : "Close"}</button>
                        }
                        <button type="button" className="btn btn-outline-light" onClick={props.confirm}>{props.saveButton ? props.saveButton : "Save changes"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
