class EventManager {
    constructor() {
        this.events = {};
    }

    subscribe(event_name, id, func) {
        if (!(event_name in this.events)) {
            this.events[event_name] = {};
        }
        if (!(id in this.events[event_name])) {
            this.events[event_name][id] = func;
        }
    }

    callEvent(event_name) {
        if (!(event_name in this.events)) {
            throw new Error(`${event_name} is not in the list of events! You need to subscribe to the event before`);
        }
        for (let event_id in this.events[event_name]) {
            this.events[event_name][event_id]();
        }
    }
}

const eventManager = new EventManager();

export default eventManager;
