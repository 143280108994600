const BoxColors = {
    indigo: "bg-info",
    blue: "bg-primary",
    green: "bg-success",
    yellow: "bg-warning",
    red: "bg-danger",
    black: "bg-dark",
    gray: "bg-secondary"
};

export default BoxColors;
