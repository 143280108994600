import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eventManager from "../../utils/event/eventManager";
import { getRequest, postRequest } from "../../utils/fetchWrapper";
import Toast, { ToastType } from "../utils/Toast";
import PasswordSettings from "../utils/subComponents/profile/PasswordSettings";
import ProfileSettings from "../utils/subComponents/profile/ProfileSettings";
import CompanySettings from "../utils/subComponents/profile/CompanySettings";
import verifyNames from "../../utils/verifications/verifyNames";
import verifyCompany from "../../utils/verifications/verifyCompany";
import { isEuropean } from "../../utils/country/countryUtils";

export default function Profile() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [user, setUser] = useState({
        firstName: undefined,
        lastName: undefined,
        email: "xavier.login@example.com",
        isCompany: false,
        countryCode: null,
        companySettings: null
    });
    const navigate = useNavigate();

    function refresh() {
        getRequest("/dashboard/profile/user").then(result => {
            setUser({
                firstName: result.first_name,
                lastName: result.last_name,
                email: result.email,
                countryCode: result.country_code,
                isCompany: result.is_company,
                companySettings: result.company_settings
            });
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    function editUser(user_settings) {
        const first_name = user_settings.first_name;
        const last_name = user_settings.last_name;
        const country = user_settings.country;
        if (!first_name) {
            setError("You need to specify the first name");
        } else if (!last_name) {
            setError("You need to specify the last name");
        } else if (user.isCompany && first_name === user.firstName && last_name === user.lastName) {
            setError("First name and last name are the same as before.")
        } else if (!user.isCompany && first_name === user.firstName
            && last_name === user.lastName && country === user.countryCode) {
            setError("Your profile settings are the same as before");
        } else {
            if (!verifyNames(setError, first_name, last_name)) {
                return;
            }
            if (!user.isCompany) {
                if (!country) {
                    setError("Please select a valid country");
                    return;
                }
            }
            postRequest("/dashboard/profile/edit-user", {
                first_name: first_name,
                last_name: last_name,
                country: !user.isCompany ? country : null
            }).then(result => {
                if (result.error) {
                    setError(result.error);
                } else {
                    refresh();
                    setSuccess("Succesfully updated your profile");
                }
            });
        }
    }

    function editPassword(settings) {
        const new_password = settings.new_password;
        if (!new_password) {
            setError("You need to specify a new password");
        } else if (new_password !== settings.confirm_password) {
            setError("Password missmatch");
        } else {
            postRequest("/dashboard/profile/edit-password", {
                new_password: new_password
            }).then(result => {
                if (result.error) {
                    setError(result.error);
                } else {
                    setSuccess("Succesfully changed password");
                }
            })
        }
    }

    function disconnect(event) {
        event.preventDefault();
        postRequest("/dashboard/profile/disconnect").then(result => {
            if (result.error) {
                // should never happen
                setError(result.error);
            } else {
                eventManager.callEvent("userDisconnect");
                navigate("/register");
            }
        });
    }

    function companyVerifications(name, city, zip_code, country, vat, address) {
        if (!verifyCompany(setError, name, address, city, zip_code, vat, country)) {
            return false;
        }
        if (!country) {
            setError("Please specify the country where your company is located");
            return false;
        }
        if (name === user.companySettings.name
            && city === user.companySettings.city
            && zip_code === user.companySettings.zip
            && country === user.countryCode
            && vat === user.companySettings.vat
            && address === user.companySettings.address) {

            setError("Your company settings are the same as before");
            return false;
        }
        return true;
    }

    function editCompany(formResult) {
        const name = formResult.name;
        const city = formResult.city;
        const zip_code = formResult.zip_code;
        const country = formResult.country;
        const vat = formResult.vat;
        const address = formResult.address;
        if (companyVerifications(name, city, zip_code, country, vat, address)) {
            postRequest("/dashboard/profile/edit-company", {
                name: name,
                city: city,
                zip_code: zip_code,
                country: country,
                vat: isEuropean(country) ? vat : null,
                address: address
            }).then(result => {
                if (result.error) {
                    setError(result.error);
                } else {
                    refresh();
                    setSuccess("Succesfully changed company details");
                }
            });
        }
    }

    let error_toast = null;
    if (error) {
        error_toast = <Toast title="Error" closeFunction={() => setError(null)} content={error} />;
    }

    let success_toast = null;
    if (!error && success) {
        success_toast = <Toast toastType={ToastType.success} title="Success" closeFunction={() => setSuccess(null)} content={success} />;
    }

    const password_settings = <PasswordSettings editPassword={editPassword} />

    if (user.isCompany) {
        return (<div>
            {error_toast}
            {success_toast}
            <div className="row">
                <div className="col-md-6">
                    <ProfileSettings editUser={editUser}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        email={user.email}
                        disconnect={disconnect} />
                    {password_settings}
                </div>
                <div className="col-md-6">
                    <CompanySettings
                        editCompany={editCompany}
                        name={user.companySettings.name}
                        address={user.companySettings.address}
                        countryCode={user.countryCode}
                        city={user.companySettings.city}
                        zipCode={user.companySettings.zip}
                        vat={user.companySettings.vat}
                    />
                </div>

            </div>
        </div>)
    } else {
        return (
            <div>
                {error_toast}
                {success_toast}
                <div className="row">
                    <div className="col-md-6">
                        <ProfileSettings editUser={editUser}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            disconnect={disconnect}
                            countryCode={user.countryCode} />
                    </div>
                    <div className="col-md-6">
                        {password_settings}
                    </div>
                </div>
            </div>
        )
    }
}
