import { useState } from "react";
import queryString from 'query-string';
import jwt from 'jwt-decode';
import Toast from "../utils/Toast";

import assertAlive from "../../utils/jwt/assertAlive";
import authHandler from "../../utils/AuthHandler";
import { useNavigate } from "react-router-dom";


function TokenError() {
    return <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
                <b>Blockchain APIs</b>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Invalid or expired link. Please reset your password again using <a href='/forgot-password'>this link</a></p>
                </div>
            </div>
        </div>
    </div>
}

export default function ResetPassword() {
    const [error, setError] = useState("");
    const parsed = queryString.parse(window.location.search);

    const navigate = useNavigate();

    if (parsed.q) {
        try {
            const decoded_token = jwt(parsed.q);
            assertAlive(decoded_token);
            if (decoded_token.action !== "reset_password") {
                return <TokenError />
            }
        } catch (error) {
            return <TokenError />
        }
    } else {
        return <TokenError />
    }

    async function resetPassword(e) {
        e.preventDefault();
        const password = e.target.password.value;
        const password_confirm = e.target.passwordconfirm.value;
        if (!password) {
            setError("Please specify a new password");
        } else if (password !== password_confirm) {
            setError("Password missmatch")
        } else {
            const res = await authHandler.resetPassword(password, parsed.q);
            if (res.error) {
                setError(res.error);
            } else {
                navigate("/reset-succesful");
            }
        }
    }

    let toast = null;
    if (error) {
        toast = <Toast title="Error" content={error} closeFunction={() => setError("")} />;
    }

    return (<div className="register-page">
        {toast}
        <div className="register-box">
            <div className="register-logo">
                <p><b>Blockchain APIs</b></p>
            </div>
            <div className="card">
                <div className="card-body register-card-body">
                    <p className="login-box-msg">Reset your password</p>
                    <form onSubmit={resetPassword}>
                        <div className="input-group mb-3">
                            <input name="password" type="password" className="form-control" placeholder="Password" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input name="passwordconfirm" className="form-control" type="password" placeholder="Confirm password" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Reset password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
}
