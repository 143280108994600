import CountrySelector from "../../form/CountrySelector";
import Form from "../../form/Form";
import Input from "../../form/Input";

export default function ProfileSettings(props) {
    return (
        <Form title={<span><i className="fas fa-user"></i> Profile settings</span>} onSubmit={props.editUser}
            button={null} buttonSize="col-md-2" useRow={true} reset={false} >
            <div className="col-md-6">
                <Input label="First name" inputId="first_name" value={props.firstName} />
            </div>
            <div className="col-md-6">
                <Input label="Last name" inputId="last_name" value={props.lastName} />
            </div>
            {props.countryCode && <div className="col-12">
                <CountrySelector defaultValue={props.countryCode} />
            </div>}
            <div className="col-12">
                <Input label="Email" value={props.email} inputId="email" disabled={true} />
            </div>
            <div className="col-md-4">
                <button className="btn btn-primary btn-block" type="submit" ><span><i class="fa-solid fa-pen-to-square"></i> Edit</span></button>
            </div>
            <div className="offset-md-5 col-md-3">
                <button className="btn btn-danger btn-block" onClick={props.disconnect}>Disconnect</button>
            </div>
        </Form>
    );
}
