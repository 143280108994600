import Form from "../utils/form/Form";
import "../../css/verticalCenter.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Toast from "../utils/Toast";
import { postRequest } from "../../utils/fetchWrapper";
import Confirmation from "../utils/alerts/Confirmation";

/**
 * This page is a page that is used to contact us for a custom plan
 */
export default function ContactPage() {
    const [error, setError] = useState("");
    const [buttonsAreDisabled, setButtonAreDisabled] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const navigate = useNavigate();

    async function sendForm(result) {
        setButtonAreDisabled(true);
        const user_requirements = result.requirements;
        if (!user_requirements) {
            setError("Please write your requirements");
        } else {
            const msg = await postRequest("/plan/contact-us", {
                message: user_requirements
            });
            if (msg.error) {
                setError(msg.error);
                setButtonAreDisabled(false);
            } else {
                setShowSuccessModal(true);
            }
        }
    }

    function cancel() {
        navigate("/");
    }

    let toast = null;
    if (error) {
        toast = <Toast title="Error"
            closeFunction={() => setError("")}
            content={error} />
    }
    let modal = null;
    if (showSuccessModal) {
        modal = <Confirmation title="Succesfuly sent email"
            close={() => setShowSuccessModal(false)}
            saveButton="Ok"
            hideNoButton={true}
            body={`Thank you for contacting us we will get back to you shortly. Until then you can choose our free plan to start using our services.`}
            confirm={() => navigate("/")}
            color="success" />
    }

    return (
        <div className="vertical-center">
            {toast}
            {modal}
            <div className="container d-flex justify-content-center">
                <div className="w-60">
                    <Form title={<span><i className="fas fa-address-book"></i> Contact Us</span>} onSubmit={sendForm}
                        button={null} buttonSize="col-md-2" useRow={true} reset={false} >
                        <div className="col-12 pb-3">
                            <div className="form-group">
                                <label className="pb-2">Please provide us details about your specific requirements</label>
                                <textarea name="requirements" className="form-control" rows="8" placeholder="Some specific requirements like amount of requests that you are planning to make"></textarea>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button className={"btn btn-danger btn-block " + (buttonsAreDisabled ? "disabled" : "")} onClick={cancel}>Cancel</button>
                        </div>

                        <div className="offset-md-5 col-md-4">
                            <button className={"btn btn-primary btn-block " + (buttonsAreDisabled ? "disabled" : "")} type="submit" >Send</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
