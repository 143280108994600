export default function MenuItem(props) {
  if (props.link) {
    return (
      <li className="nav-item">
        <a href={props.link} target="_blank" rel="noreferrer" className={"nav-link " + (props.selected ? 'active' : '')}>
          <i className={"nav-icon " + props.icon} />
          <p>
            {props.name}
          </p>
        </a>
      </li>
    )
  }

  return (
    <li className="nav-item">
      <a href={"/" + props.menu_id} className={"nav-link " + (props.selected ? 'active' : '')}>
        <i className={"nav-icon " + props.icon} />
        <p>
          {props.name}
        </p>
      </a>
    </li>
  );
}
