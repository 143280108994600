/**
 * These countries are considered as being part of France and that we
 * pay VAT for them.
 * 
 * The impact on our program is that we pay VAT for companies and individuals
 * that come from these countries. (we pay 20% VAT included in the price).
 * 
 * Please not that there are also other countries that are part of France like:
 * "MF" (Saint Martin), but we don't pay VAT for them.
 * 
 * Data provided by the CEO of Clarensia on 21/03/2023
 */
export const FRENCH_VAT_COUNTRIES = [
    "FR", "MQ", "GP", "RE"
];

/**
 * These countries are countries that we considere as being part of
 * the European Union.
 * 
 * The inpact on our program is that companies that are from European countries
 * must provide their VAT number and this VAT number must be written in the
 * invoice that we give to the user.
 * 
 * This list source is this: https://gist.github.com/henrik/1688572, please note
 * that we manually excluded UK (United Kindgom) from this list, as it is no
 * longer part of the European Union.
 * This is a more accurate link: https://gist.github.com/henrik/1688572?permalink_comment_id=2596891#gistcomment-2596891
 */
export const EUROPEAN_UNION_COUNTRIES = [
    "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE",
    "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", // EU 28 except GB
    "GF", "GP", "MQ", "ME", "YT", "RE", "MF", // Outermost Regions (OMR)
    "GI", "AX", // Special cases
    "AD", "LI", "MC", "SM", "VA" // Microstates
    // Did not added (Other, Overseas Countries and Territories (OCT))
];

/**
 * As a French company, we are not allowed to do business with certain
 * countries. This list contain all of the countries that we are not allowed
 * to do business with.
 * 
 * The impact on our program is that user can't subscribe if their country
 * of origin is in one of these countries.
 * 
 * This list is called Embargo.
 * 
 * Data provided by the CEO of Clarensia on 21/03/2023
 */
export const BANNED_COUNTRIES = [
    "AF",
    "BY", "BI",
    "CG", "CD", // "Congo" and "Congo, Democratic Republic of the"
    "KP", // (North korea: Korea, Democratic People's Republic of https://en.wikipedia.org/wiki/ISO_3166-2:KP)
    "GW", "GN", // "GQ", Guinea and Guinea-Bissau except Equatorial Guinea
    "HT", "IR", "IQ", "LB", "LY", "ML", "MM", "NI",
    "CF", "RU", "SO", "SS", "SD", "SY", "TN", "TR",
    "VE", "YE", "ZW"
];
