function TableHeader(props) {
    return (
        <thead>
            <tr>
                {props.header.map(header => <th>{header}</th>)}
            </tr>
        </thead>
    )
}

function TableBody(props) {
    return (
        <tbody>
            {props.body.map(row => <tr>{row.map(col => <td>{col}</td>)}</tr>)}
        </tbody>
    )
}

export default function Table(props) {
    return (
        <div className="card">
            {props.title ? (<div className={`card-header ${props.color}`}>
                <h3 className="card-title">{props.title}</h3>
            </div>) : ""}
            <div className="card-body p-0">
                <table className="table table-striped">
                    <TableHeader header={props.header} />
                    <TableBody body={props.body} />
                </table>
            </div>
        </div>
    )
}
