import { useState } from "react";
import Form from "../utils/form/Form";
import Input from "../utils/form/Input";
import Toast from "../utils/Toast";
import { postRequest } from "../../utils/fetchWrapper";
import Confirmation from "../utils/alerts/Confirmation";
import { DISCORD_INVITE } from "../../config";

export default function Support() {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    function openDiscord() {
        window.open(DISCORD_INVITE, "_blank", 'noopener');
    }

    async function contact(form_result) {
        const subject = form_result.subject;
        const category = form_result.category;
        const description = form_result.description;
        if (!subject) {
            setError("Please sepcify the subject of your demand");
        } else if (!category) {
            setError("Please specify the category of your demand");
        } else if (!description) {
            setError("Please specify the description of your demand");
        } else {
            const result = await postRequest("/dashboard/contact/contact", {
                subject: subject,
                category: category,
                description: description
            });
            if (result.error) {
                setError(result.error);
            } else {
                setSuccess(true);
            }
        }
    }

    
    let toast = null;
    if (error) {
        toast = <Toast title="Error" closeFunction={() => setError(null)} content={error} />;
    }
    let modal = null;
    if (success) {
        modal = <Confirmation title="Thank you for contacting us"
            close={() => setSuccess(false)}
            saveButton="Ok"
            closeButton={null}
            body={"You have received an email with the details about your query. Verify your emails regularly, we will answer you shortly."}
            confirm={() => setSuccess(false)}
            color="success"
            hideNoButton={true} />
    }

    return <div>
        {toast}
        {modal}
        <div className="row">
            <div className="col-md-6">
                <Form
                    title={<span><i className="fa-solid fa-envelope"></i> Contact Form</span>}
                    onSubmit={contact}
                    reset={true}
                    customButton={<button className="btn btn-primary float-right px-3" type="submit"><i className="fa-solid fa-envelope pr-1"></i> Send</button>}>
                    <Input label="Subject" placeholder="Quick description of why you contact us" inputId="subject" />
                    <div className="form-group">
                        <label>Category</label>
                        <select className="custom-select" name="category" >
                            <option>Subscription</option>
                            <option>Billing</option>
                            <option>Technical issue</option>
                            <option>Feature Suggestion</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea name="description" class="form-control" rows="5" placeholder="Your specific query, for example: Your needs for a custom plan or other"></textarea>
                    </div>
                </Form>
            </div>
            <div className="col-md-6">
                <div className="card card-secondary">
                    <div className="card-header">
                        <h3 className="card-title"><i className="fa-brands fa-discord"></i> Discord support</h3>
                    </div>
                    <div className="card-body">
                        For faster responses you can create a ticket and get a call with us on our discord:
                        <div className="d-flex justify-content-center pt-3">
                            <button className="btn btn-lg btn-secondary" onClick={openDiscord}><i className="fa-brands fa-discord"></i> Join Discord</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
}