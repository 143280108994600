export const ToastType = {
    danger: 'bg-danger',
    warning: 'bg-warning',
    success: 'bg-success',
};

export default function Toast(props) {
    let toastType = ToastType.danger;
    if (props.toastType) {
        toastType = props.toastType
    };
    return (
        <div id="toastsContainerTopRight" className="toasts-top-right fixed">
            <div className={"toast " + toastType + " fade show"} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <strong className="mr-auto">{props.title}</strong>
                    <small>{props.subTitle}</small>
                    <button data-dismiss="toast" type="button" onClick={props.closeFunction} className="ml-2 mb-1 close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="toast-body">{props.content}</div>
            </div>
        </div>

    );
}
