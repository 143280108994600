import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { STRIPE_PUBLIC_KEY } from "../../config";
import eventManager from "../../utils/event/eventManager";
import { getRequest, postRequest } from "../../utils/fetchWrapper";

export default function BillAccount() {
    const [message, setMessage] = useState("Billing your account. Please wait...");
    const [error, setError] = useState("");

    useEffect(() => {
        let wait_confirmation = false;

        const interval = setInterval(async () => {
            if (!wait_confirmation) {
                const payment_status = await getRequest("/plan/payment-status");
                if (payment_status.payment_status === "SUCCEEDED") {
                    setMessage("Payment succeeded! Redirecting...");
                    return eventManager.callEvent("planChoosen");
                } else if (payment_status.payment_status === "FAILED") {
                    setError("Payment failed");
                } else if (payment_status.payment_status === "REQUIRE_ACTION") {
                    wait_confirmation = true;
                    const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
                    const { error: errorAction, paymentIntent } = await stripe.handleCardAction(payment_status.client_secret);
                    if (errorAction) {
                        setError(errorAction.message);
                    } else {
                        const confirm_result = await postRequest("/plan/confirm-payment", {
                            payment_intent_id: paymentIntent.id
                        });
                        if (confirm_result.error) {
                            setError(confirm_result.error);
                        } else {
                            setMessage("Payment succeeded! Redirecting...");
                            return eventManager.callEvent("planChoosen");
                        }
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    let content = <div className="card-body login-card-body">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h6 className="mt-3">{message}</h6>
    </div>

    if (error) {
        content = <div className="card-body login-card-body">
            <h6>{error}</h6>
            <a href="/">Change card details</a>
        </div>
    }
    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>Thank you!</b>
                    <h5 className="text-muted">We are saving your payment details and billing your account, it might take up to few seconds please wait</h5>
                </div>
                <div className="card text-center box-shadow">
                    {content}
                </div>
            </div>

        </div>
    );
}
