export default function Input(props) {
    let input = <input className="form-control" type={props.type ? props.type : "text"} id={props.inputId} name={props.inputId} defaultValue={props.value} placeholder={props.placeholder} />;
    if (props.disabled) {
        input = <input className="form-control" type={props.type ? props.type : "text"} id={props.inputId} name={props.inputId} value={props.value} placeholder={props.placeholder} disabled />
    }

    if (props.icon) {
        input = <div className="input-group">
            {input}
            <div className="input-group-append">
                <div className="input-group-text">
                    <span className={props.icon} />
                </div>
            </div>
        </div>
    }

    if (props.label) {
        return (
            <div className="form-group">
                <label>{props.label}</label>
                {input}
            </div>
        )
    } else {
        return input;
    }
}