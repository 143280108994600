export default function MultiTab(props) {

    let active = "active";
    let active_pane = "active";

    return (
        <div className="card">
            <div className="card-header d-flex p-0">
                <h3 className="card-title p-3">{props.title}</h3>
                <ul className="nav nav-pills ml-auto p-2">
                    {props.lines.map(line => {
                        const ret = <li className="nav-item"><a className={`nav-link ${active}`} href={`#${line.id}`} data-toggle="tab">{line.displayName}</a></li>;
                        active = "";
                        return ret;
                    })}
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content">
                    {props.lines.map(line => {
                        const ret = <div className={`tab-pane ${active_pane}`} id={line.id}>{line.content}</div>;
                        active_pane = "";
                        return ret;
                    })}
                </div>
            </div>
        </div>
    );
}