import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CreateAccount from '../auth/CreateAccount';
import ForgotPassword from '../auth/ForgotPassword';
import ForgotPasswordMessage from '../auth/ForgotPasswordMessage';
import Login from '../auth/Login';
import Register from '../auth/Register';
import ResetPassword from '../auth/ResetPassword';
import ResetSuccesful from '../auth/ResetSuccesfull';
import ThankYou from '../auth/ThankYou';

export default function LoginRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Navigate replace to='/register' />}/>
                <Route path='login' element={<Login />} />
                <Route path='register' element={<Register />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='thank-you' element={<ThankYou />} />
                <Route path='create-account' element={<CreateAccount />} />
                <Route path='forgot-password-message' element={<ForgotPasswordMessage />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='reset-succesful' element={<ResetSuccesful />} />
                <Route path='*' element={<Navigate replace to="/register" />} />
            </Routes>
        </BrowserRouter>
    );
}
