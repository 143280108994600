export default function assertAlive(decoded) {
    // FROM: https://github.com/auth0/jwt-decode/issues/53#issuecomment-413069022
    // valueOf should prevent timezone issues
    const now = Date.now().valueOf() / 1000
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
        throw new Error(`token expired: ${JSON.stringify(decoded)}`)
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
        throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
    }
}
