export const SERVER = "https://backend.blockchainapis.io";
export const OUR_URL = "https://dashboard.blockchainapis.io";
export const API_URL = "https://api.blockchainapis.io";
export const DOC_URL = "https://docs.blockchainapis.io";
// export const API_URL = "http://localhost:8000";

// TODO: Change it with production key
export const STRIPE_PUBLIC_KEY = "pk_live_51MWclGHdBonShFun3Nc4kAa9uf7fLLFfnVekQliH1HBQR48yTzV0txq5o3griKrTYLhSQ8JvB3rUh4A2b84SZOUB00qAyHDupn";

export const DISCORD_INVITE = "https://discord.gg/GphRMJXmS5";

// These are the plans with all of the advantages.
// We need to keep same between server and here (especially with the price)
export const PLANS = [
    {
        id: 0,
        price: 0,
        name: "Developer",
        features: [
            "Requests: 1M",
            "Requests per second: 100",
            "Support: Discord",
            "Integration: All",
        ],
        button: {
            full: false,
            text: "Choose"
        },
        subtitle: "+$2 per additional million requests"
    },
    {
        id: 1,
        price: 19,
        name: "Premium",
        features: [
            "Requests: 20M",
            "Requests per second: 250",
            "Support: Discord",
            "Integration: All"
        ],
        button: {
            full: true,
            text: "Choose"
        },
        subtitle: "+$2 per additional million requests"
    },
    {
        id: 2,
        price: 49,
        name: "Pro",
        features: [
            "Requests: 50M",
            "Requests per second: 1000",
            "Support: Premium",
            "Integration: All"
        ],
        button: {
            full: true,
            text: "Choose"
        },
        subtitle: "+$2 per additional million requests"
    },
    {
        id: 3,
        price: "199",
        name: "Dedicated",
        features: [
            "Private server just for you",
            "Server closest to your location",
            "Advanced support",
            "Highest speed possible",
        ],
        button: {
            full: true,
            text: "Choose"
        },
        subtitle: "Unlimited requests"
    }
];
