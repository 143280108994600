import { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

export default function CountrySelector(props) {
    const countryOptions = useMemo(() => countryList().getData(), []);
    let defaultValue = undefined;
    if (props.defaultValue) {
        defaultValue = { label: countryList().getLabel(props.defaultValue), value: props.defaultValue }
    }

    const select = (
        <Select options={countryOptions}
            placeholder="Select your country"
            name="country"
            defaultValue={defaultValue}
            onChange={props.onChange}
            styles={{
                control: (baseStyles, _) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                }),
                placeholder: (defaultStyles) => ({
                    ...defaultStyles,
                })
            }} />
    );
    if (props.hideLabel) {
        return select;
    }
    return (
        <div className="form-group">
            <label>Country</label>
            {select}
        </div>
    );
}
