export default function Copy(props) {

    function copy(event) {
        event.preventDefault();
        navigator.clipboard.writeText(props.value);
    }

    return (
        <div className="form-group">
            <label for={props.inputId}>{props.label}</label>
            <div className="input-group">
                <input id={props.inputId} className="form-control" type="text" value={props.value} readOnly />
                <div className="input-group-append">
                    <button onClick={copy} className="input-group-text bg-primary">Copy</button>
                    {props.additionalButton && props.additionalButton}
                </div>
            </div>
        </div>
    );
}
