export function formatNumber(value) {
    return value.toLocaleString('en-US');
}

export function getAdditionalPrice(additional_requests, price_per_additional_million) {
    return Math.ceil(additional_requests / 1000000) * price_per_additional_million;
}

export function formatAdditional(additional_requests, price_per_additional_million) {
    const ret_value = getAdditionalPrice(additional_requests, price_per_additional_million);
    return formatCurrency(ret_value);
}

export function formatCurrency(cents) {
    let cents_str = cents.toString();
    while (cents_str.length < 3) {
        cents_str = "0" + cents_str;
    }
    const ret = "$" + cents_str.slice(0, cents_str.length - 2) + "." + cents_str.slice(cents_str.length - 2);
    return ret;
}

