import { SERVER } from "../config";

export async function getFile(path) {
    return await fetch(SERVER + path, {
        method: "GET",
        credentials: 'include',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true
        },
        mode: "cors"
    });
}

export async function getRequest(path) {
    const json = await fetch(SERVER + path, {
        method: "GET",
        credentials: 'include',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true
        },
        mode: "cors"
    });
    return await json.json();
}

export async function postRequest(path, body) {
    const json = await fetch(SERVER + path, {
        method: "POST",
        credentials: 'include',
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true
        },
        body: (body ? JSON.stringify(body) : undefined)
    });
    return await json.json();
}

export async function deleteRequest(path) {
    const json = await fetch(SERVER + path, {
        method: "DELETE",
        credentials: 'include',
        mode: "cors",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true
        }
    });
    return await json.json();
}
