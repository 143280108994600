export default function ForgotPasswordMessage() {
    return <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
                <b>Blockchain APIs</b>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Please check your mailbox, you have received a message to reset your password.</p>
                </div>
            </div>
        </div>
    </div>
}
