export default function verifyNames(setError, first_name, last_name) {
    // source: https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
    const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    let invalid = "";
    if (!regex.test(first_name)) {
        invalid += "first name";
        setError("Invalid first name, please use only lowercase/upper case letters, accents are allowed.");
    } else if (!regex.test(last_name)) {
        if (invalid) {
            invalid += " and "
        } else {
            invalid += " ";
        }
        invalid += "last name";
    }

    if (invalid) {
        setError("Invalid " + invalid + ", please use only lowercase/upper case letters. Accents are allowed.");
        return false;
    } else {
        return true;
    }
}
