export default function HorizontalDescription(props) {
    return (
        <div className="card">
            <div className={`card-header ${props.color}`}>
                <h3 className="card-title">
                    <i className={props.icon} /> {props.title}
                </h3>
            </div>
            <div className="card-body">
                {props.lines.map(line => {
                    return (
                        <dl className="row" key={line[0]}>
                            <dt className="col-sm-4">{line[0]}</dt>
                            <dd className="col-sm-8">{line[1]}</dd>
                        </dl>
                    )
                })}
                {props.children}
            </div>
        </div>
    );
}