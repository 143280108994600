import { isEuropean } from "../country/countryUtils";


export default function verifyCompany(setError, company, address, city, zip_code, vat_number, country_code) {
    if (!company) {
        setError("Please specify your company name");
        return false;
    } else if (!address) {
        setError("Please specify your company address");
        return false;
    } else if (!city) {
        setError("Please specify the city where your company is located");
        return false;
    } else if (!zip_code) {
        setError("Please specify the zip code of city your company is located");
        return false;
    } else if (isEuropean(country_code) && !vat_number) {
        setError("Please specify your company VAT number");
        return false;
    }
    return true;
}
