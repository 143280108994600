import Form from "../../form/Form";
import Input from "../../form/Input";

export default function PasswordSettings(props) {
    return (
        <Form title="Password settings" color="secondary" onSubmit={props.editPassword} button="Change password" reset={true}>
            <Input label="New password" type="password" inputId="new_password" placeholder="Type your new password..." />
            <Input label="Confirm password" type="password" inputId="confirm_password" placeholder="Confirm password..." />
        </Form>
    );
}
