import { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import AdminInterface from './components/AdminInterface';
import LoginRouter from "./components/routers/LoginRouter";
import PlanRouter from "./components/routers/PlanRouter";
import authHandler from "./utils/AuthHandler";
import eventManager from "./utils/event/eventManager";
import { getRequest } from "./utils/fetchWrapper";

function App() {
    // With local storage, we go way faster, but be carefull, the token can expire
    // local storage have only strings
    const [isConnected, setConnected] = useState(localStorage.getItem("is_connected") === "true");
    const [isPlanChoosen, setPlanChoosen] = useState(localStorage.getItem("is_plan_choosen") === "true");

    useEffect(() => {
        const currentPath = window.location.pathname;

        // we bypass authentication on this url as it is only to verify
        // stripe.
        if (currentPath !== "/confirm-payment") {
            authHandler.is_connected().then(is_connected => {
                if (is_connected) {
                    setConnected(true);
                } else {
                    authHandler.try_reconnect().then(reconnect => {
                        if (reconnect) {
                            setConnected(true);
                        } else {
                            setConnected(false);
                        }
                    });
                }
            });
        }

        eventManager.subscribe("userConnect", "App.jsx", () => setConnected(true));
        eventManager.subscribe("userDisconnect", "App.jsx", () => setConnected(false));
        eventManager.subscribe("planChoosen", "App.jsx", () => setPlanChoosen(true));

        return () => authHandler.stopAuthRefresher();
    }, []);

    useEffect(() => {
        authHandler.stopAuthRefresher();
        if (isConnected) {
            authHandler.startAuthRefresher();
            localStorage.setItem("is_connected", "true");
            getRequest("/dashboard/plan").then(result => {
                if (result.plan != null) {
                    setPlanChoosen(true);
                }
            });
        } else {
            localStorage.setItem("is_connected", "false");

            // we remove the plan choosen because the user might reconnect/create a new account
            setPlanChoosen(false);
        }
    }, [isConnected]);

    useEffect(() => {
        if (isPlanChoosen) {
            localStorage.setItem("is_plan_choosen", "true");
        } else {
            localStorage.setItem("is_plan_choosen", "false");
        }
    }, [isPlanChoosen]);

    if (!isConnected) {
        return (
            <div className="wrapper">
                <LoginRouter />
            </div>
        );
    }

    if (!isPlanChoosen) {
        return (
            <div className="wrapper">
                <PlanRouter />
            </div>
        )
    }

    return (
        <div className="wrapper">
            <BrowserRouter>
                <Routes>
                    <Route index element={<Navigate replace to="/api-usage" />} />
                    <Route path="/bill-account" element={<Navigate replace to="/api-usage" />} />
                    <Route path="/confirm-payment" element={<Navigate replace to="/api-usage" />} />
                    <Route path="/login" element={<Navigate replace to="/api-usage" />} />
                    <Route path="/:menu_id" element={<AdminInterface />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
