import { useParams } from "react-router-dom";

import Header from './menuManager/Header';
import Footer from './menuManager/Footer';
import Menus from './menuManager/Menus';
import ContentHeader from "./menuManager/ContentHeader";

import ApiUsage from "./dashboard/ApiUsage";
import ApiKey from "./dashboard/ApiKey";
import Profile from "./dashboard/Profile";
import { API_URL, DISCORD_INVITE } from "../config";
import Billing from "./dashboard/Billing";
import Support from "./dashboard/Support";

const menus = {
    'api-usage': {
        display_name: "API Usage",
        component: <ApiUsage />,
        icon: "fas fa-circle-info"
    },
    'api-key': {
        display_name: "API Key",
        component: <ApiKey />,
        icon: "fa-solid fa-key"
    },
    'documentation': {
        display_name: "Documentation",
        link: `${API_URL}/docs`,
        icon: "fa-solid fa-book"
    },
    'billing': {
        display_name: "Billing",
        component: <Billing />,
        icon: "fa-solid fa-file-invoice-dollar"
    },
    'support': {
        display_name: "Support",
        component: <Support />,
        icon: "fa-solid fa-envelope"
    },
    'discord': {
        display_name: "Discord",
        link: DISCORD_INVITE,
        icon: "fa-brands fa-discord"
    },
    'profile': {
        display_name: "Profile",
        component: <Profile />,
        icon: "fa-solid fa-user"
    },
};

export default function AdminInterface() {
    const { menu_id } = useParams();
    return (
        <div>
            <Header />
            <Menus allMenus={menus} selected={menu_id} />
            <div className="content-wrapper">
                <ContentHeader name={menus[menu_id].display_name} link={menu_id}/>
                <section className="content">
                    <div className="container-fluid">
                        {menus[menu_id].component}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
