import MenuItem from './MenuItem';
// import logo from "../../assets/images/logo.png";

export default function Menus(props) {
    const menus = props.allMenus;
    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a href="/" className="brand-link">
                    <span className="brand-text font-weight-light">Blockchain APIs</span>
                    {/*<img src={logo} className="img-fluid" alt="Blockchain APIs logo"/>*/}
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {Object.keys(menus).map(menu_id => 
                                <MenuItem
                                    key={menu_id}
                                    menu_id={menu_id}
                                    name={menus[menu_id].display_name}
                                    link={menus[menu_id].link}
                                    icon={menus[menu_id].icon}
                                    selected={menu_id === props.selected}
                                />
                            )}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    );
}
