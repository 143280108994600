import { BrowserRouter, Routes, Route } from "react-router-dom";
import BillAccount from "../stripe/BillAccount";
import ChoosePlanPage from "../stripe/ChoosePlanPage";
import ConfirmPayment from "../stripe/ConfirmPayment";
import ContactPage from "../stripe/ContactPage";

export default function PlanRouter() {
    return (<BrowserRouter>
            <Routes>
                <Route path="/confirm-payment" element={<ConfirmPayment />} />
                <Route path="/bill-account" element={<BillAccount />} />
                <Route path="/contact-page" element={<ContactPage />} />
                <Route path="*" element={<ChoosePlanPage />} />
            </Routes>
        </BrowserRouter>)
}
