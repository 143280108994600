import { useState } from "react";
import { useNavigate } from "react-router-dom";

import authHandler from "../../utils/AuthHandler";
import eventManager from "../../utils/event/eventManager";
import Toast from "../utils/Toast";

export default function Login() {
    const [error, setError] = useState("");
    const navigate = useNavigate();

    async function handleClick(e) {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        const remember = e.target.remember.checked;
        if (!email) {
            setError("Please specify your email");
        } else if (!password) {
            setError("Please specify your password");
        } else {
            const response = await authHandler.connect(email, password, remember);
            if (response.error) {
                setError(response.error);
            } else {
                eventManager.callEvent("userConnect");
                navigate("/");
            }
        }
    }

    let toast = null;
    if (error) {
        toast = <Toast title="Error" content={error} closeFunction={() => setError("")} />;
    }

    return (
        <div className="login-page">
            {toast}
            <div className="login-box">
                <div className="login-logo">
                    <b>Blockchain APIs</b>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in</p>
                        <form onSubmit={handleClick}>
                            <div className="input-group mb-3">
                                <input name="email" type="email" className="form-control" placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input name="password" type="password" className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input name="remember" type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </div>
                        </form>
                        <p className="mb-1">
                            <a href="forgot-password">Forgot password?</a>
                        </p>
                        <p class="mb-0">
                            <a href="register" class="text-center">Don't have an account? Sign up</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>);
}
