import queryString from 'query-string';
import jwt from 'jwt-decode';
import { useState } from "react";
import authHandler from "../../utils/AuthHandler";
import Toast from "../utils/Toast";
import { useNavigate } from "react-router-dom";
import eventManager from '../../utils/event/eventManager';
import Input from '../utils/form/Input';
import verifyNames from '../../utils/verifications/verifyNames';
import verifyCompany from '../../utils/verifications/verifyCompany';
import CountrySelector from '../utils/form/CountrySelector';
import { isEuropean } from '../../utils/country/countryUtils';

function EmailError() {
    return <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
                <b>Blockchain APIs</b>
            </div>
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Invalid or expired link. Please register again by clicking on <a href="/register">this link</a></p>
                </div>
            </div>
        </div>
    </div>
}

function assertAlive(decoded) {
    // FROM: https://github.com/auth0/jwt-decode/issues/53#issuecomment-413069022
    // valueOf should prevent timezone issues
    const now = Date.now().valueOf() / 1000
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
        throw new Error(`token expired: ${JSON.stringify(decoded)}`)
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
        throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
    }
}

export default function CreateAccount() {
    const [error, setError] = useState("");
    const [isCompany, setCompany] = useState(false);
    const [country, setCountry] = useState("");

    const navigate = useNavigate();
    const parsed = queryString.parse(window.location.search);
    let email = null;

    if (parsed.q) {
        try {
            const decoded_token = jwt(parsed.q);
            assertAlive(decoded_token);
            email = decoded_token.email;
        } catch (error) {
            return <EmailError />
        }
    } else {
        return <EmailError />
    }

    async function registerUser(e) {
        e.preventDefault();
        const first_name = e.target.first_name.value;
        const last_name = e.target.last_name.value;
        const email = e.target.email.value;
        const country = e.target.country.value;
        const company = e.target.company.value;
        const address = e.target.address.value;
        const city = e.target.city.value;
        const zip_code = e.target.zip_code.value;
        const vat = e.target.vat.value;
        const password = e.target.password.value;
        const password_confirm = e.target.passwordconfirm.value;
        const remember = e.target.remember.checked;
        if (!first_name) {
            setError("Please write your first name");
        } else if (!last_name) {
            setError("Please write your last name");
        } else if (!email) {
            setError("Please specify an email");
        } else if (!country) {
            setError("Please select your country");
        } else if (!password) {
            setError("Please specify a password");
        } else if (password !== password_confirm) {
            setError("Password missmatch");
        } else {
            if (!verifyNames(setError, first_name, last_name)) {
                return;
            }
            if (isCompany && !verifyCompany(setError, company, address, city, zip_code, vat, country)) {
                return;
            }
            const result = await authHandler.register(first_name, last_name, email, country, password, parsed.q, remember,
                isCompany, company, address, city, zip_code, vat);
            if ("error" in result) {
                setError(result.error);
            } else {
                eventManager.callEvent("userConnect");
                navigate("/");
            }
        }
    }

    let toast = null;
    if (error) {
        toast = <Toast title="Error" content={error} closeFunction={() => setError("")} />;
    }

    function countrySelect(newVal) {
        setCountry(newVal.value);
    }

    return (<div className="register-page">
        {toast}
        <div className="register-box">
            <div className="register-logo">
                <p><b>Blockchain APIs</b></p>
            </div>
            <div className={"card " + (isCompany ? "mx-n5" : "mx-n3")}>
                <div className="card-body register-card-body">
                    <p className="login-box-msg">Register a new account</p>
                    <div className='d-md-flex flex-row justify-content-between pb-2 pt-n5'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={() => setCompany(false)} defaultChecked />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                I am an individual
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={() => setCompany(true)} />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                I am a company
                            </label>
                        </div>
                    </div>

                    <form onSubmit={registerUser}>
                        <div className="mb-3">
                            <Input inputId="email" type="email" placeholder="Email" value={email} icon="fas fa-envelope" disabled={true} />
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <Input inputId="first_name" placeholder="First name" icon="fas fa-user" />
                            </div>
                            <div className="col-md-6">
                                <Input inputId="last_name" placeholder="Last name" icon="fas fa-user" />
                            </div>
                        </div>
                        <div className={"mb-3 " + (isCompany ? "" : "d-none")}>
                            <Input inputId="company" placeholder="Company name" icon="fas fa-building" />
                        </div>
                        <div className={"mb-3 " + (isCompany ? "" : "d-none")}>
                            <textarea name="address" class="form-control" rows="3" placeholder="Company address"></textarea>
                        </div>
                        <div className={"row mb-3 " + (isCompany ? "" : "d-none")}>
                            <div className="col-md-6">
                                <Input inputId="city" placeholder="City" icon="fas fa-city" />
                            </div>
                            <div className="col-md-6">
                                <Input inputId="zip_code" placeholder="Zip Code" icon="fas fa-house" />
                            </div>
                        </div>
                        <div className='mb-3'>
                            <CountrySelector onChange={countrySelect} hideLabel={true} />
                        </div>
                        <div className={"mb-3 " + (isCompany && isEuropean(country) ? "" : "d-none")}>
                            <Input inputId="vat" placeholder="Vat number" icon="fas fa-pen" />
                        </div>
                        <div className="mb-3">
                            <Input inputId="password" type="password" placeholder="Password" icon="fas fa-lock" />
                        </div>
                        <div className="mb-3">
                            <Input inputId="passwordconfirm" type="password" placeholder="Confirm password" icon="fas fa-lock" />
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                    <input name="remember" type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        Remember Me
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <button type="submit" className="btn btn-primary btn-block">Sign up</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='card-footer text-center text-muted'>
                    By signing up, you agree to our <a href='https://www.blockchainapis.io/terms.html' target='_blank' rel='noreferrer'>terms of service</a> and <a href='https://www.blockchainapis.io/privacy.html' target='_blank' rel='noreferrer'>privacy policy</a>
                </div>
            </div>
        </div>
    </div>);
}
