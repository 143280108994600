import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../config";
import CardForm from "./CardForm";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function CardDetailPage(props) {
    const options = {
        clientSecret: props.secret,
        appearance: {
            theme: "stripe"
        }
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            <CardForm amount={props.amount} />
        </Elements>
    )
}