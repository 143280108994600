import { useState } from "react";
import { isEuropean } from "../../../../utils/country/countryUtils";
import CountrySelector from "../../form/CountrySelector";
import Form from "../../form/Form";
import Input from "../../form/Input";

export default function CompanySettings(props) {
    const [currentCountry, setCurrentCountry] = useState(props.countryCode);

    return (
        <Form title={<span><i className="fas fa-building"></i> Company settings</span>}
            onSubmit={props.editCompany} useRow={true} reset={false} button={<span><i class="fa-solid fa-pen-to-square"></i> Edit</span>} color="info"
            buttonSize="col-md-4" >
            <div className="col-md-12">
                <Input label="Company name" placeholder="Company name" inputId="name" value={props.name} />
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <label>Company address</label>
                    <textarea name="address" class="form-control" rows="3" placeholder="Company address" defaultValue={props.address}></textarea>
                </div>
            </div>
            <div className="col-sm-6">
                <Input label="City" inputId="city" placeholder="City" value={props.city} />
            </div>
            <div className="col-sm-6">
                <Input label="Zip code" inputId="zip_code" placeholder="Zip code" value={props.zipCode} />
            </div>
            <div className="col-md-12">
                <CountrySelector defaultValue={props.countryCode} onChange={new_val => setCurrentCountry(new_val.value)} />
            </div>
            {
                isEuropean(currentCountry) && <div className="col-md-12">
                    <Input label="VAT number" placeholder="Vat number" value={props.vat} inputId="vat" />
                </div>
            }
        </Form>
    );
}
